<!-- Use este arquivo como base para novos módulos de CRUD, -->
<!-- apenas copiando ele e colando na pasta respectiva -->

<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onUpdate="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    primaryKey="id"
    :alertMessage="alertMessage"
  />
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {
  schemaBoolean, schemaSelect,
  schemaSqlEditor,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
export default {
  name: "tintometrico-base",
  components: {
    CrudBase,
  },
  props: [
    'fabricantes'
  ],
  data() {
    return {
      alertMessage: 'Vincule um código de produto à base para preencher o EAN de acordo com o código de barras de cadastro.',
      url: "/admin/tintometrico/base",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Nome", field: "nome", sortable: false },
        { label: "EAN", field: "ean", sortable: false },
        { label: "Embalagem", field: "embalagem_nome", sortable: false },
        { label: "ID Externo", field: "id_externo", sortable: false },
        { label: "Fabricante", field: "nome_fabricante", sortable: false }
      ],
      formInputs: {
        produto_id: schemaTextField('ID Produto', false, 3),
        nome: schemaTextField('Descrição', false, 3, this.disabledOption()),
        fabricante_id: schemaSelect('Fabricante', false, 3, this.fabricantes, null, this.disabledOption()),
        ean: schemaTextField('EAN', false, 3, this.disabledOption()),
        id_externo: schemaTextField('ID Externo', false, 3, this.disabledOption()),
      },
    };
  },
  methods: {
    disabledOption() {
      return {
        "disabled": true
      }
    }
  }
};
</script>
