<template>
  <CTabs variant="tabs" class="nav-tabs-boxed" v-if="dataLoaded">
    <CTab title="Maquina">
      <TintometricoMaquina
          :fabricantes="fabricantes"
          :filiais="filiais"
          :key="uniqueKey"
      />
    </CTab>
    <CTab title="Bases">
      <TintometricoBase
          :fabricantes="fabricantes"
          :key="uniqueKey"
      />
    </CTab>
    <CTab title="Cores">
      <TintometricoCor
          :fabricantes="fabricantes"
          :key="uniqueKey"
      />
    </CTab>
    <CTab title="Corantes">
      <TintometricoCorante
          :fabricantes="fabricantes"
          :key="uniqueKey"
      />
    </CTab>
  </CTabs>
</template>

<script>

import TintometricoMaquina from "@/views/Tintometrico/TintometricoMaquina.vue";
import TintometricoBase from "@/views/Tintometrico/TintometricoBase.vue";
import TintometricoCor from "@/views/Tintometrico/TintometricoCor.vue";
import TintometricoCorante from "@/views/Tintometrico/TintometricoCorante.vue";
import { get} from '@/helpers/apiRequest';

export default {
  name: 'Tintometrico',
  components: {
    TintometricoMaquina,
    TintometricoBase,
    TintometricoCor,
    TintometricoCorante
  },
  data(){
    return {
        filiais: {},
        fabricantes: {},
        uniqueKey: 0,
        dataLoaded: false
    };
  },
  async mounted() {
    await this.loadData().then(() => {
      setTimeout(() => {
        this.dataLoaded = true
      }, 1000)
    })
  },
  methods: {
    loadFiliais(){
      get('/admin/tintometrico/filiais-ativas')
          .then((json) => {
            json.map((filial) => {
              this.$set(this.filiais, filial.id, filial.desc);
            });
          })
    },
    loadFabricantes(){
      get('/admin/tintometrico/fabricantes')
          .then((json) => {
            json.map((fabricante) => {
              this.$set(this.fabricantes, fabricante.id, fabricante.chave);
            });
          })
    },
    async loadData() {
      await this.loadFabricantes();
      await this.loadFiliais();
    }
  },
}

</script>
