<!-- Use este arquivo como base para novos módulos de CRUD, -->
<!-- apenas copiando ele e colando na pasta respectiva -->

<template>
  <crud-base
    :url="url"
    :columns="columns"
    :enableSearch="true"
    :on-update="() => {}"
    :formInputs="formInputs"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {
  schemaColorPicker,
  schemaSelect,
  schemaTextField
} from "@/helpers/form";

export default {
  name: "tintometrico-cor",
  components: {
    CrudBase,
  },
  props: [
    'fabricantes'
  ],
  data() {
    return {
      url: "/admin/tintometrico/cor",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Cód.", field: "id_externo", sortable: false },
        { label: "Nome", field: "nome", sortable: false },
        { label: "Hexadecimal", field: "hexadecimal", sortable: false },
        { label: "Fabricante", field: "fabricante_nome", sortable: false },
        { label: "Ativo", field: "ativo", sortable: false }
      ],
      formInputs: {
        nome: schemaTextField('Nome', false, 12, this.disabledOption()),
        hexadecimal: schemaTextField('Hexadecimal', false, 2, this.disabledOption()),
        id_externo: schemaTextField('Código', false, 4, this.disabledOption()),
        fabricante_id: schemaSelect('Fabricante', false, 4, this.fabricantes, null, this.disabledOption()),
        ativo: schemaSelect('Ativo', true, 4, {
          '1': 'Sim',
          '0': 'Não',
        }),
      },
    };
  },
  methods: {
    disabledOption() {
      return {
        "disabled": true
      }
    }
  }
};
</script>
