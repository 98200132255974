<template>
  <div>
    <modal name="syncMaquinaInfo" height="auto" width="1024" :scrollable="true" :clickToClose="true">
    <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                  Última Sincronização -
                  {{ maquina ? maquina.descricao : '' }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-hover" style="margin-left: auto; margin-right: auto;">
                  <thead>
                  <tr>
                    <th>Chave</th>
                    <th>Valor</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(valor, chave) in maquina.ultimaSincronizacao">
                    <td>{{ chave }}</td>
                    <td style="word-wrap: break-word;">{{ valor }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <CButton class="button button-success" @click="syncMaquinas(maquina)">Sincronizar</CButton>
        <button class="button button-primary" v-on:click="loadMaquinas">Atualizar</button>
        <button class="button" v-on:click="close">Fechar</button>
      </div>
    </modal>
  </div>
</template>
<script>
import CloseModal from "../../components/CloseModal";

export default {
  name: "syncMaquinaInfo",
  props: [
    "close",
    "maquina",
    "loadMaquinas",
    "syncMaquinas"
  ],
  components: {
    CloseModal,
  }
};
</script>
