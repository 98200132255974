<template>
  <div>
    <Loading :isActive="loading"></Loading>
    <TintometricoSyncMaquinaInfo
        :maquina="this.currentMaquina"
        :close="closeSyncInfo"
        :loadMaquinas="loadMaquinas"
        :syncMaquinas="openSyncConfirmation"
    />
    <crud-base
      :url="url"
      :columns="columns"
      :onDelete="() => {}"
      :onUpdate="() => {}"
      :onStore="() => {}"
      :enableSearch="true"
      :formInputs="formInputs"
      primaryKey="id"
      :reload="reloadMaquinas"
      @reload="setReload"
      @itens="changeEditingMaquina"
    >
      <template v-slot:action-buttons="{model}">
        <CButton
            size="sm"
            @click="openSyncConfirmation(model)"
            color="secondary"
            title="Sincronizar"
        >
          <fa-icon icon="sync" />
        </CButton>
        <CButton
            size="sm"
            @click="openSyncInfo(model)"
            :color=getInfoColor(model)
            title="Ultima sincronização"
        >
          <fa-icon icon="info-circle" />
        </CButton>
      </template>
    </crud-base>
  </div>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import TintometricoSyncMaquinaInfo from "./TintometricoSyncMaquinaInfo";
import Loading from "../../components/Loading";

import {
  schemaSelect,
  schemaTextField
} from "@/helpers/form";

import {get, post} from '@/helpers/apiRequest';
import _find from 'lodash/find';
import clone from 'just-clone';

export default {
  name: "tintometrico-maquina",
  components: {
    CrudBase,
    TintometricoSyncMaquinaInfo,
    Loading
  },
  props: [
      'filiais',
      'fabricantes'
  ],
  data() {
    return {
      maquinas: {},
      loading: false,
      reloadMaquinas: false,
      currentMaquina: {},
      url: "/admin/tintometrico/maquina",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Descrição", field: "descricao", sortable: false },
        { label: "Fabricante", field: "fabricante_nome", sortable: false },
      ],
      formInputs: {
        descricao: schemaTextField('Descrição', true, 6),
        filial_id: schemaSelect('Filial', true, 6, this.filiais),
        fabricante_id: schemaSelect('Fabricante', true, 3, this.fabricantes),
        database_ip: schemaTextField('IP', false, 3),
        database_port: schemaTextField('Porta', false, 2),
        database_name: schemaTextField('Nome do banco', false, 2),
        database_charset: schemaTextField('Charset', false, 2),
        database_user: schemaTextField('Usuário', false, 2),
        database_password: schemaTextField('Senha', false, 2),
      }
    };
  },
  methods: {
    setReload(shouldReload) {
      this.reloadMaquinas = shouldReload;
    },
    sync(model) {
      post(`/admin/tintometrico/maquina/${model.id}/sync`, model);
    },
    openSyncInfo(model) {
      this.currentMaquina = model;
      this.$modal.show('syncMaquinaInfo');
    },
    closeSyncInfo() {
      this.$modal.hide('syncMaquinaInfo');
    },
    loadMaquinas() {
      this.reloadMaquinas = true;
    },
    changeEditingMaquina(updatedMaquinas) {
      if (this.currentMaquina) {
        let maquina = _find(updatedMaquinas, { id: this.currentMaquina.id });

        if (maquina) {
          this.currentMaquina = clone(maquina);
        }
      }
    },
    getInfoColor(model) {
      const { ultimaSincronizacao } = model;

      if (ultimaSincronizacao &&
          ultimaSincronizacao.id &&
          !ultimaSincronizacao.sucesso) {
        return 'danger';
      }

      if (ultimaSincronizacao && ultimaSincronizacao.sucesso) {
        return 'success';
      }

      return 'secondary';
    },
    openSyncConfirmation(model) {
      this.$swal({
        title: 'Sincronizar',
        text: `Tem certeza que deseja sincronizar a maquina ${model.descricao}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        if (res.isConfirmed == true) {
          this.sync(model);

          this.$swal({
            title: "Sucesso!",
            text: "Maquina adicionada na fila de sincronização!",
            showCancelButton: false,
            confirmButtonText: "Fechar",
            showConfirmButton: true,
            icon: "success",
          });
        }
      });
    }
  }
};
</script>
